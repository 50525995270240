// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery")
//= require rails-ujs
//= require jquery
//= require tinymce-jquery

require("@rails/ujs").start()

window.jQuery = window.$ = require('jquery')
//СЕЛЕКТ
require("packs/select2")
require("packs/topbox")

//ЗАГРУЗКА КАРТИНОК
require("packs/jquery.fileuploader")

//ВАЛИДАЦИЯ
require("packs/parsley/parsley.min")
require("packs/parsley/i18n/ru")

require("./jquery.menu-aim")
require("./bootstrap.bundle.min")
require("./plugins")
require("./magnific")


import Swiper, { Navigation, Pagination, Scrollbar }  from 'swiper';
// import topbox  from 'topbox';
import tippy from 'tippy.js';


//Поп-апы
let app = {
    initTopbox: function ($elements) {
        // iFrames require a data-lightbox-type="iframe" attribute.
        // AJAX requires a data-lightbox-type="ajax" attribute.
        // Inline requires a data-lightbox-type="inline" attribute.
        // Video iFrames require a data-lightbox-type="video-iframe" attribute.
        if ($elements.length) {
            $elements.topbox({
                keyboardNav: false,
                beforeShowLightbox: function (lightbox) {
                    $('.topbox_overlay').remove();
                },
                afterShowLightbox: function (lightbox) {
                    $('body').on('click', '.js-close-topbox', function () {
                        $('.topbox_close').trigger('click');
                    });
                }
            });
        }
    },

    openTopbox: function (url) {
        var $link = $('<a href="' + url + '" class="js-topbox" data-lightbox-type="ajax" aria-haspopup="dialog"></a>');
        this.initTopbox($link);
        $link
            .click()
            .remove();
    }
};

$(document).ready(function () {



    app.initTopbox($('.js-topbox'));

    //активная ссылка
    $(function() {
      $('.js-active-link [href]').each(function() {
    	if (this.href == window.location.href) {
    	  $(this).addClass('st_active');
    	}
      });
    });

    // enable file uploader plugin https://innostudio.de/fileuploader/documentation/#examples
    $('input.js_load').fileuploader({
        captions: 'ru',
        addMore: true,
        enableApi: true,
        inputNameBrackets: true,
        thumbnails: {
            onItemShow: function (item) {
                // add sorter button to the item html
                item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Sort"><i class="fileuploader-icon-sort"></i></button><div class="js_thistext fileuploader-action"></div>');
                item.html.find('.js_thistext').append(item.html.closest('label').find('.image-sortable>.image:first-child'));
            }
        },
        sorter: {
            selectorExclude: null,
            placeholder: null,
            scrollContainer: window,
            onSort: function (list, listEl, parentEl, newInputEl, inputEl) {
            }
        }
    });
    $('input.js_load_one').fileuploader({
        captions: 'ru',
        changeInput: true,
        limit: 1,
        thumbnails: {}
    });


    // СЕЛЕКТ
    // 'data-placeholder': 'Выберите или добавьте бренд', 'data-tags':' true', 'data-ajax--url':'/brands/searchbrand', 'data-ajax--dataType':'json', 'data-width': '100%'
    $("select").select2({
        language: "ru"
    });

    //Быстрый просмотр
    $(document).on('click', '.js_quickview_btn', function () {
        $(this).parent().find('input.d-none').click();
    });


    //Добавить поле - страница тип товара
    (function () {
        $(document).on('click', 'form .remove_fields', function (event) {
            $(this).prev('input[type=hidden]').val('1');
            $(this).closest('fieldset').hide();
            return event.preventDefault();
        });

        $(document).on('click', 'form .add_fields', function (event) {
            let regexp, time;
            time = new Date().getTime();
            regexp = new RegExp($(this).data('id'), 'g');
            $(this).before($(this).data('fields').replace(regexp, time));
            return event.preventDefault();
        });

    }).call(this);

    // Потвердил город
    $('.js_city_select_yes').click(function () {
        document.cookie = "city_select=yes";
        location.reload();
    });

    // Выбор типа каталога
    $('.js_catalog_select_grid').click(function () {
        document.cookie = "hit_catalog=grid";
        location.reload();
    });
    $('.js_catalog_select_list').click(function () {
        document.cookie = "hit_catalog=list";
        location.reload();
    });

    // Выбор типа каталога
    $('.js_version_select_mobile').click(function () {
        document.cookie = "is_mobile=mobile; path=/; expires=Tue,";
        location.reload();
    });
    $('.js_version_select_desktop').click(function () {
        document.cookie = "is_mobile=desktop; path=/; expires=Tue,";
        location.reload();
    });

    // ФИЛЬТРАЦИЯ
    $('.js_hide_filter').click(function () {
        $(this).toggleClass('st_hide');
    });

    //ТИП ДОСТАВКИ ПЕРЕКЛЮЧЕНИЕ
    $('.js_order_type_pickup_btn').click(function () {
        $('.js_order_type_delivery').addClass('d-none');
        $('.js_order_type_pickup').removeClass('d-none');
    });
    $('.js_order_type_delivery_btn').click(function () {
        $('.js_order_type_delivery').removeClass('d-none');
        $('.js_order_type_pickup').addClass('d-none');
    });

    //КНОПКА НАВЕРХ
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $('.to-top').fadeIn();
        } else {
            $('.to-top').fadeOut();
        }
    });

    $('.to-top').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
    });

    //СКРОЛЛ И КЛИК ПО ТАБУ КОМПЛЕКТАЦИИ
    $('#js-equipment').click(function (e) {
        e.preventDefault();
        $('#nav-equipment-tab').click();
    });
    $('#js-equipment').on('click', function () {
        var scrollContent = $('#nav-equipment-tab').offset().top;
        $('html, body').animate({scrollTop: scrollContent}, 600);
    });
    $(".nav-tabs a").click(function(){
        $(this).tab('show');
    });

    //МАКСА ТЕЛЕФОНА
    function setCursorPosition(pos, elem) {
        elem.focus();
        if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
        else if (elem.createTextRange) {
            let range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd("character", pos);
            range.moveStart("character", pos);
            range.select()
        }
    }

    function mask(event) {
        let matrix = "+7 (___) ___-__-__",
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, "");
        if (def.length >= val.length) val = def;
        this.value = matrix.replace(/./g, function (a) {
            return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
        });
        if (event.type == "blur") {
            if (this.value.length == 2) this.value = "";
        } else setCursorPosition(this.value.length, this)
    }

    let inputTel = $('input[type="tel"]');
    inputTel.on("input", mask);
    inputTel.on("focus", mask);
    inputTel.on("blur", mask);

    //ВАЛИДАЦИЯ
    $('.js_valid_form').parsley();

    //МЕНЮ
    $(".js_hamburger").click(function () {
        $(this).toggleClass("st_active");
        $('body').toggleClass("st_menu_show");
    });

    $(".js_header_btn").click(function () {
        $('.js_header_btn').removeClass('st_active');
        $(this).addClass("st_active");

        var findDiv = '.' + $(this).data('menu');

        $('.js_header_body').removeClass('st_active');
        $(findDiv).addClass("st_active");
    });

    //ПРОСТЫЕ ТАБЫ
    $('.js-nav-name').click(function(){
        let id = $(this).attr('data-nav-name'),
        content = $('.tab-pane[data-nav-content="'+ id +'"]');

        $('.tab-pane.active').removeClass('active show');
        content.addClass('active');
    });

    //ТАЙМЕР АКЦИИ
    function getTimeRemaining(endtime) {
        let t = Date.parse(endtime) - Date.parse(new Date()),
            seconds = Math.floor((t / 1000) % 60),
            minutes = Math.floor((t / 1000 / 60) % 60),
            hours = Math.floor((t / (1000 * 60 * 60)) % 24),
            days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            total: t,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }

    function initializeClock(id, endtime) {
        let clock = document.getElementById(id),
            end_clock = 'deadline-' + id,
            start_clock = id,
            daysSpan = clock.querySelector(".days"),
            hoursSpan = clock.querySelector(".hours"),
            minutesSpan = clock.querySelector(".minutes"),
            secondsSpan = clock.querySelector(".seconds");

        function updateClock() {
            let t = getTimeRemaining(endtime);

            if (t.total <= 0) {
                document.getElementById(start_clock).className = "d-none";
                document.getElementById(end_clock).className = "deadline-message-visible";
                clearInterval(timeinterval);
                return true;
            }

            daysSpan.innerHTML = t.days;
            hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
            minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
            secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);
        }

        updateClock();
        let timeinterval = setInterval(updateClock, 1000);
    }

    $('.js_countdown').each(function () {
      console.log($(this).data('time'));
        initializeClock($(this).attr('id'), $(this).data('time'));
    });


});

//ПОП-АП ВХОДА И ОТЗЫВЫ
$(function () {
    return $("form#sign_in_user, form#sign_up_user").bind("ajax:success", function () {
        return location.reload();
    }).bind("ajax:error", function () {
        let error_messages = '<div class=\'alert alert-danger\'>Не верный пароль или эл.почта</div>';
        return $(this).closest('.modal').find('.modal-footer').html(error_messages);
    });
});

$(function () {
    return $("form#report_new").bind("ajax:success", function () {
        return location.reload();
    }).bind("ajax:error", function () {
        let error_messages = '<div class=\'alert alert-danger\'>Ошибка, попробуйте еще раз</div>';
        return $(this).closest('.modal').find('.modal-footer').html(error_messages);
    });
});

$('body').on('click', '.js_reviews_rating', function () {
    let rating = $(this).html();
    $(this).parent().attr('data-rating', rating);
    $(this).closest('.js_rating_block').find('.js_reviews_input').val(rating);
});

tippy('[data-tippy-content]', {
    theme: 'light',
    placement: 'bottom',
    allowHTML: true,
});

/*! loadCSS for FireFOX */
(function (w) {
    "use strict";
    // rel=preload support test
    if (!w.loadCSS) {
        w.loadCSS = function () {
        };
    }
    // define on the loadCSS obj
    var rp = loadCSS.relpreload = {};
    // rel=preload feature support test
    // runs once and returns a function for compat purposes
    rp.support = (function () {
        var ret;
        try {
            ret = w.document.createElement("link").relList.supports("preload");
        } catch (e) {
            ret = false;
        }
        return function () {
            return ret;
        };
    })();

    // if preload isn't supported, get an asynchronous load by using a non-matching media attribute
    // then change that media back to its intended value on load
    rp.bindMediaToggle = function (link) {
        // remember existing media attr for ultimate state, or default to 'all'
        var finalMedia = link.media || "all";

        function enableStylesheet() {
            link.media = finalMedia;
        }

        // bind load handlers to enable media
        if (link.addEventListener) {
            link.addEventListener("load", enableStylesheet);
        } else if (link.attachEvent) {
            link.attachEvent("onload", enableStylesheet);
        }

        // Set rel and non-applicable media type to start an async request
        // note: timeout allows this to happen async to let rendering continue in IE
        setTimeout(function () {
            link.rel = "stylesheet";
            link.media = "only x";
        });
        // also enable media after 3 seconds,
        // which will catch very old browsers (android 2.x, old firefox) that don't support onload on link
        setTimeout(enableStylesheet, 3000);
    };

    // loop through link elements in DOM
    rp.poly = function () {
        // double check this to prevent external calls from running
        if (rp.support()) {
            return;
        }
        var links = w.document.getElementsByTagName("link");
        for (var i = 0; i < links.length; i++) {
            var link = links[i];
            // qualify links to those with rel=preload and as=style attrs
            if (link.rel === "preload" && link.getAttribute("as") === "style" && !link.getAttribute("data-loadcss")) {
                // prevent rerunning on link
                link.setAttribute("data-loadcss", true);
                // bind listeners to toggle media back
                rp.bindMediaToggle(link);
            }
        }
    };

    // if unsupported, run the polyfill
    if (!rp.support()) {
        // run once at least
        rp.poly();

        // rerun poly on an interval until onload
        var run = w.setInterval(rp.poly, 500);
        if (w.addEventListener) {
            w.addEventListener("load", function () {
                rp.poly();
                w.clearInterval(run);
            });
        } else if (w.attachEvent) {
            w.attachEvent("onload", function () {
                rp.poly();
                w.clearInterval(run);
            });
        }
    }


    // commonjs
    if (typeof exports !== "undefined") {
        exports.loadCSS = loadCSS;
    } else {
        w.loadCSS = loadCSS;
    }
}(typeof global !== "undefined" ? global : this));

//КАРТА ОФОРМЛЕНИЯ
//const circleToPolygon = require("circle-to-polygon");

//const coordinates = [36.690161, 50.528673]; //[lon, lat]
//const radius = 30000; // in meters
//const options = {numberOfEdges: 32}; //optional, that defaults to { numberOfEdges: 32 }

//let polygon = circleToPolygon(coordinates, radius, options);

//console.dirxml(JSON.stringify(polygon));


if (document.getElementById("map") != null) {
    // ymaps.ready(init);
    //
    //
    // function init() {
    //     var suggestView1 = new ymaps.SuggestView('suggest', {
    //         boundedBy: [[50.79, 36.3], [50.39, 36.89]]
    //     });
    //
    //     var $address_to = '';
    //
    //     $('#suggest').on('input', function(event){
    //         console.log($(this).val());
    //         $address_to = $(this).val();
    //         rute();
    //     });
    //
    //     suggestView1.events.add("select", function(e){
    //         $address_to = e.get('item').value;
    //         rute();
    //     });
    //
    //     function rute(){
    //         routePanelControl.routePanel.state.set({
    //             fromEnabled: true,
    //             from: 'Белгород, Ботаническая 17',
    //             toEnabled: true,
    //             to: $address_to
    //         });
    //     }
    //
    //
    //     // Стоимость за километр.
    //     var DELIVERY_TARIFF = 15,
    //         // Минимальная стоимость.
    //         MINIMUM_COST = 249,
    //         MINIMUM_COST_BIG = 499,
    //         myMap = new ymaps.Map('map', {
    //             center: [60.906882, 30.067233],
    //             zoom: 10,
    //             controls: []
    //         }, {suppressMapOpenBlock: true}),
    //         // Создадим панель маршрутизации.
    //         routePanelControl = new ymaps.control.RoutePanel({
    //             options: {
    //                 // Добавим заголовок панели.
    //                 visible: false,
    //                 showHeader: false,
    //             }
    //         }),
    //         zoomControl = new ymaps.control.ZoomControl({
    //             options: {
    //                 size: 'small',
    //                 float: 'none',
    //                 position: {
    //                     bottom: 30,
    //                     right: 10
    //                 }
    //             }
    //         });
    //     // Пользователь сможет построить только автомобильный маршрут.
    //     routePanelControl.routePanel.options.set({
    //         types: {auto: true}
    //     });
    //
    //     //Если вы хотите задать неизменяемую точку "откуда", раскомментируйте код ниже.
    //     routePanelControl.routePanel.state.set({
    //         fromEnabled: false,
    //         from: 'Белгород, Ботаническая 17'
    //     });
    //
    //     myMap.controls.add(routePanelControl).add(zoomControl);
    //
    //     // Получим ссылку на маршрут.
    //     routePanelControl.routePanel.getRouteAsync().then(function (route) {
    //
    //         // Зададим максимально допустимое число маршрутов, возвращаемых мультимаршрутизатором.
    //         route.model.setParams({results: 1}, true);
    //
    //         // Повесим обработчик на событие построения маршрута.
    //         route.model.events.add('requestsuccess', function () {
    //             var activeRoute = route.getActiveRoute();
    //             if (activeRoute) {
    //                 // Получим протяженность маршрута.
    //                 var length = route.getActiveRoute().properties.get("distance"),
    //                     // Вычислим стоимость доставки.
    //                     price = calculate(Math.round(length.value / 1000)),
    //                     price_big = calculate_big(Math.round(length.value / 1000)),
    //                     // Создадим макет содержимого балуна маршрута.
    //                     balloonContentLayout = ymaps.templateLayoutFactory.createClass(
    //                         '<span class="d-none">Расстояние: ' + length.text + '.</span>' +
    //                         '<div style="font-weight: bold; font-style: italic; margin-right: 20px;">Курьерская доставка: ' + price + ' ₽ <br>Грузовая доставка: ' + price_big + ' ₽</div>'
    //                     );
    //                 // Зададим этот макет для содержимого балуна.
    //                 route.options.set('routeBalloonContentLayout', balloonContentLayout);
    //                 // Откроем балун.
    //                 activeRoute.balloon.open(myMap.getCenter(), { balloonContentLayout }, { closeButton: true });
    //                 // Откроем балун.
    //                 //activeRoute.balloon.open();
    //                 $('.js_price_delivery').text('Курьерская доставка - ' + price + ' ₽');
    //                 $('.js_price_delivery_big').text('Грузовая доставка - ' + price_big + ' ₽');
    //                 $('.js_delivery_body').removeClass('d-none');
    //                 $('.hint').addClass('d-none');
    //                 $('#order_address').val($('.ymaps-2-1-76-route-panel-input__icon_last + .ymaps-2-1-76-route-panel-input__input').val());
    //
    //
    //                 var jsonObj = [];
    //                 itemJson = {}
    //                 itemJson ["coor"] = route.getActiveRoute().properties.get("boundedBy")[0];
    //                 itemJson ["price_courier"] = price;
    //                 itemJson ["price_delivery"] = price_big;
    //
    //                 jsonObj.push(itemJson);
    //
    //                 $('.js_service_info').val(JSON.stringify(jsonObj));
    //             }
    //
    //         });
    //
    //     });
    //
    //     // Функция, вычисляющая стоимость доставки.
    //     function calculate(routeLength) {
    //         var  routeLength = routeLength - 10;
    //         if (routeLength > 0) {
    //             return Math.max(routeLength * DELIVERY_TARIFF + MINIMUM_COST, MINIMUM_COST);
    //         } else {
    //             return Math.max(MINIMUM_COST);
    //         }
    //
    //     }
    //
    //     // Функция, вычисляющая стоимость доставки Грузовой.
    //     function calculate_big(routeLength) {
    //         return Math.max(routeLength * DELIVERY_TARIFF + MINIMUM_COST_BIG, MINIMUM_COST_BIG);
    //     }
    // }


    ymaps.ready(init);

    function init() {
        var
            myPlacemark,
            myRoute,
            length = 0,
            PRICE1KM = 35,
            old_coordinates = 0,
            myMap = new ymaps.Map('map', {
                center: [36.690161, 50.528673],
                zoom: 9,
                controls: ['searchControl']
            }, {suppressMapOpenBlock: true}),
            deliveryPoint = new ymaps.GeoObject({
                geometry: {type: 'Point'},
                properties: {iconCaption: ''}
            }, {
                preset: 'islands#blackDotIconWithCaption',
                draggable: true,
                iconCaptionMaxWidth: '215'
            }),
            searchControl = myMap.controls.get('searchControl');

        searchControl.options.set({noPlacemark: true, placeholderContent: 'Введите адрес доставки'});
        myMap.geoObjects.add(deliveryPoint);

        function onZonesLoad(json) {
            // Слушаем клик на карте.
            myMap.events.add('click', function (e) {
                var coords = e.get('coords');
                getAddress(coords);
            });

            // Определяем адрес по координатам (обратное геокодирование).
            function getAddress(coords) {
                ymaps.geocode(coords).then(function (res) {
                    highlightResult(res.geoObjects.get(0));
                });
            }


            // Добавляем зоны на карту.
            var deliveryZones = ymaps.geoQuery(json).addToMap(myMap);

            // Задаём цвет и контент балунов полигонов.
            deliveryZones.each(function (obj) {
                console.log(obj);
                obj.options.set({
                    fillColor: obj.properties.get('fill'),
                    fillOpacity: obj.properties.get('fill-opacity'),
                    strokeColor: obj.properties.get('stroke'),
                    strokeWidth: obj.properties.get('stroke-width'),
                    strokeOpacity: obj.properties.get('stroke-opacity')
                });
                //obj.properties.set('balloonContent', obj.properties.get('description'));

                obj.events.add('click', function (obj) {
                    var coords = obj.get('coords');
                    getAddress(coords);
                });
            });

            // Проверим попадание результата поиска в одну из зон доставки.
            searchControl.events.add('resultselect', function (e) {
                console.log('Проверим попадание результата поиска в одну из зон доставки.');
                console.log(searchControl.getResultsArray()[e.get('index')]);
                highlightResult(searchControl.getResultsArray()[e.get('index')]);
            });

            // Проверим попадание метки геолокации в одну из зон доставки.
            // myMap.controls.get('geolocationControl').events.add('locationchange', function (e) {
            //     console.log('1');
            //     highlightResult(e.get('geoObjects').get(0));
            // });

            // При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку.
            deliveryPoint.events.add('dragstart', function () {
                console.log('При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку')
                deliveryPoint.properties.set({iconCaption: '', balloonContent: ''});
                deliveryPoint.options.set('iconColor', 'black');
            });

            console.log('По окончании перемещения метки вызываем функцию выделения зоны доставки.');
            // По окончании перемещения метки вызываем функцию выделения зоны доставки.
            deliveryPoint.events.add('dragend', function () {
                console.log('Перемещение');
                highlightResult(deliveryPoint);
            });

            function highlightResult(obj) {
                // Сохраняем координаты переданного объекта.
                var coords = obj.geometry.getCoordinates(),
                    // Находим полигон, в который входят переданные координаты.
                    polygon = deliveryZones.searchContaining(coords).get(0);
                console.log('Находим полигон, в который входят переданные координаты');
                if (polygon) {
                    // Уменьшаем прозрачность всех полигонов, кроме того, в который входят переданные координаты.
                    deliveryZones.setOptions('fillOpacity', '0.25');
                    polygon.options.set('fillOpacity', '0.5');

                    // Перемещаем метку с подписью в переданные координаты и перекрашиваем её в цвет полигона.
                    console.log('Перемещаем метку по переданным координатам - 1.');
                    deliveryPoint.geometry.setCoordinates(coords);
                    deliveryPoint.options.set('iconColor', polygon.properties.get('fill'));

                    // удаление маршрута на карте
                    myRoute && myMap.geoObjects.remove(myRoute);

                    // Задаем подпись для метки.
                    if (typeof (obj.getThoroughfare) === 'function') {
                        //Вывод цены из полигона
                        setData(obj);
                    } else {
                        // Если вы не хотите, чтобы при каждом перемещении метки отправлялся запрос к геокодеру,
                        // закомментируйте код ниже.
                        ymaps.geocode(coords, {results: 1}).then(function (res) {
                            var obj = res.geoObjects.get(0);
                            //Вывод цены из полигона
                            setData(obj);
                        });
                    }
                } else {
                    // Если переданные координаты не попадают в полигон, то задаём стандартную прозрачность полигонов.
                    deliveryZones.setOptions('fillOpacity', 0.4);
                    // Перемещаем метку по переданным координатам.
                    console.log('Перемещаем метку по переданным координатам.');
                    deliveryPoint.geometry.setCoordinates(coords);

                    // удаление маршрута на карте
                    myRoute && myMap.geoObjects.remove(myRoute);

                    console.log('построенние маршрута');
                    // построенние маршрута
                    ymaps.route([
                        {type: 'wayPoint', point: [36.690161, 50.528673]},
                        {type: 'wayPoint', point: coords}
                    ], {
                        mapStateAutoApply: true
                    }).then(function (route) {
                        route.getPaths().options.set({
                            // внешний вид маршрута
                            strokeColor: '0000ffff',
                            opacity: 0.5
                        });

                        //Растояние в метрах переводим в км
                        length = Math.round(route.getLength() / 1000);

                        // добавляем маршрут на карту
                        myMap.geoObjects.add(myRoute = route);

                        //Вывод цены за 1 км
                       var price = length * PRICE1KM;
                       setDataKM(obj, price)

                        console.log('Задаём контент балуна и метки.');
                       // console.log(price);
                        // Задаём контент балуна и метки.
                        deliveryPoint.properties.set({
                            iconCaption: 'Доставка ' + length + 'км.',
                            balloonContent: 'Cвяжитесь с оператором',
                            balloonContentHeader: ''
                        });

                        // Перекрашиваем метку в чёрный цвет.
                        deliveryPoint.options.set('iconColor', 'black');

                        // return
                    });
                }

                function setDataKM(obj, price) {
                    console.log('setDataKM');
                    var address = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');
                    if (address.trim() === '') {
                        address = obj.getAddressLine();
                    }

                    console.log(obj.getAddressLine());
                    $('.ymaps-2-1-79-searchbox-input__input').val(obj.getAddressLine());
                    //searchControl.search(obj.getAddressLine());

                    $('#suggest').val(address);
                    $('.js_price_delivery_big').html('<span class="h6 text-muted">Стоимость грузовой доставки</span>' + '<span class="h4">' + price + ' ₽</span>');
                    var priceGoods = $('#itogo-price').data('itogoprice');
                    $('.js_itogo_price_sum').html(Number(priceGoods) + Number(price) + '₽');
                    $('#order_total_sum').val(Number(priceGoods) + Number(price));
                    $('.js_delivery_body').removeClass('d-none');
                    $('.hint').addClass('d-none');
                };

                function setData(obj) {
                    console.log('setData');
                    var address = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');
                    if (address.trim() === '') {
                        address = obj.getAddressLine();
                    }

                    var description = polygon.properties.get('description');
                    var price = polygon.properties.get('price');
                        console.log(obj.getAddressLine());
                    $('.ymaps-2-1-79-searchbox-input__input').val(obj.getAddressLine());
                    //searchControl.setParent(obj.getAddressLine());

                    $('#suggest').val(address);
                    $('.js_price_delivery_big').html('<span class="h6 text-muted">Стоимость грузовой доставки </span>' + '<span class="h4">' + price + ' ₽</span>');
                    var priceGoods = $('#itogo-price').data('itogoprice');
                    $('.js_itogo_price_sum').html(Number(priceGoods) + Number(price) + '₽');
                    $('#order_total_sum').val(Number(priceGoods) + Number(price));
                    $('.js_delivery_body').removeClass('d-none');
                    $('.hint').addClass('d-none');

                    deliveryPoint.properties.set({
                        iconCaption: address,
                        balloonContent: address,
                        balloonContentHeader: description
                    });
                }
            }
        }

        $.ajax({
            url: '/global/data.geojson',
            dataType: 'json',
            success: onZonesLoad
        });
    }
}

/*------------------------------------------------------------------
МОБИЛЬНАЯ ВЕРСИЯ
-------------------------------------------------------------------*/
//--Переключение доставки и оплаты--/
$(document).ready(function () {
    $(document).on('change', '.js_cart_change', function () {
        $(this).next().click();
    });

    $('input:radio[name="payment"]').change(function () {
        $('.option-hidden').hide();
        if ($(this).val() == 'cards') {
            $('#show-cards-info').show();
        }
        if ($(this).val() == 'cash') {
            $('#show-cash-info').show();
        }
    });

    $('input:radio[name="shipping"]').change(function () {
        $('.option-hidden').hide();
        if ($(this).val() == 'delivery') {
            $('#show-delivery-cards').show();
        }
        if ($(this).val() == 'pickup') {
            $('#show-pickup-info').show();
        }
    });
});


$(document).ready(function() {
    //--Поиск по фильтру--/
    var elements = document.querySelectorAll('.js-form-search');
    for (let i = 0; i < elements.length; ++i) {
        let newId = elements[i].dataset.searchid;

        let elbody = document.getElementById('js-form-search-body-' + newId);
        let elinput = document.getElementById('js-form-search-input-' + newId);

        if (elbody.clientHeight > 130) {
            elbody.classList.toggle('max-height-body-search');
            elinput.classList.add('d-block');
        };

        elements[i].onkeyup = function () {
            find();
        };
    }

    $('.form-search-input').on("click", function (event) {
        let eventid = event.target.id;
        let eventbodyid = document.getElementById(eventid).nextElementSibling.id;

        let find = function () {
            let inp = document.getElementById(eventid);//сам инпут
            let parent = document.getElementById(eventbodyid),//блок в котором будем искать
                divs = parent.getElementsByTagName('label'),//блок где находится непосредственно строки, которые нужно найти
                len = divs.length;

            for (var i = 0; i < len; i++) {//for begin
                if (divs[i].childNodes.length == 1 && divs[i].innerHTML.toUpperCase().includes(inp.value.toUpperCase()) == false && inp.value != '') {
                    divs[i].style.display = 'none';
                    divs[i].closest("div").style.display = 'none';
                } else if (divs[i].style.display != 'block') {
                    divs[i].style.display = 'block';
                    divs[i].closest("div").style.display = 'block';
                }
            }//for end

        }//find end

        document.onkeyup = function () {
            find();
        };
    });

    //ПОКАЗАТЬ ЕЩЕ В КАТАЛОГЕ
    $('.next_page').on('click', function(e) {
        var url;
        e.preventDefault();
        url = $(this).attr('href');
        return $.getScript(url);
    });

    $('.js-content-item-more').on('click', function() {
        $('.content-item-wrapper').toggleClass('view');
    });


    $('.ajax-popup-link').magnificPopup({
      type: 'ajax',
      closeBtnInside: true,
        showCloseBtn: true,
        callbacks: {
            open: function() {
                function setCursorPosition(pos, elem) {
                    elem.focus();
                    if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
                    else if (elem.createTextRange) {
                        let range = elem.createTextRange();
                        range.collapse(true);
                        range.moveEnd("character", pos);
                        range.moveStart("character", pos);
                        range.select()
                    }
                }

                function mask(event) {
                    let matrix = "+7 (___) ___-__-__",
                        i = 0,
                        def = matrix.replace(/\D/g, ""),
                        val = this.value.replace(/\D/g, "");
                    if (def.length >= val.length) val = def;
                    this.value = matrix.replace(/./g, function (a) {
                        return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
                    });
                    if (event.type == "blur") {
                        if (this.value.length == 2) this.value = "";
                    } else setCursorPosition(this.value.length, this)
                }

                $('body').on('input', 'input[type="tel"]', mask);
                $('body').on('focus', 'input[type="tel"]', mask);
                $('body').on('blur', 'input[type="tel"]', mask);

                $('body').on('click', '.close-btn', function (event) {
                    event.preventDefault();
                    $.magnificPopup.close();
                });
            }
        }
    });


    $('.image-popup-link').magnificPopup({
      type: 'image'
      // other options
    });

    const warmet = new Swiper('.warmet', {
      slidesPerView: 1,
      modules: [Navigation, Pagination],
      spaceBetween: 20,
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      },
        navigation: {
            nextEl: '.swiper-warmet-next',
            prevEl: '.swiper-warmet-prev',
        },
    });

    const compare = new Swiper('.swiper-compare', {
        slidesPerView: 1.5,
        modules: [Scrollbar],
        spaceBetween: 20,
        breakpoints: {
          576: {
            slidesPerView: 2.2
          },
          992: {
            slidesPerView: 3.2
          },
          1200: {
            slidesPerView: 4.5
          }
        },
        scrollbar: {
            el: '.swiper-scrollbar-compare',
            draggable: true,
          },
      });

});

!function(t) {
  "function" == typeof define && define.amd && define.amd.jQuery ? define(["jquery"], t) : t("undefined" != typeof module && module.exports ? require("jquery") : jQuery)
}(function(lt) {
  "use strict";
  function i(t, b) {
    function e(t) {
      if (!(!0 === G.data(Dt + "_intouch") || 0 < lt(t.target).closest(b.excludedElements, G).length)) {
        var e = t.originalEvent ? t.originalEvent : t;
        if (!e.pointerType || "mouse" != e.pointerType || 0 != b.fallbackToMouseEvents) {
          var o,
              i = e.touches,
              n = i ? i[0] : e;
          return Z = kt, i ? J = i.length : !1 !== b.preventDefaultEvents && t.preventDefault(), Y = B = q = null, V = 1, X = z = Q = F = U = 0, (a = {})[st] = P(st), a[pt] = P(pt), a[ct] = P(ct), a[ut] = P(ut), W = a, $(), O(0, n), !i || J === b.fingers || b.fingers === $t || x() ? (tt = C(), 2 == J && (O(1, i[1]), Q = z = M(K[0].start, K[1].start)), (b.swipeStatus || b.pinchStatus) && (o = v(e, Z))) : o = !1, !1 === o ? (v(e, Z = Et), o) : (b.hold && (rt = setTimeout(lt.proxy(function() {
            G.trigger("hold", [e.target]),
            b.hold && (o = b.hold.call(G, e, e.target))
          }, this), b.longTapThreshold)), k(!0), null)
        }
      }
      var a
    }
    function o(t) {
      var e,
          o,
          i,
          n,
          a,
          r,
          l,
          s,
          p = t.originalEvent ? t.originalEvent : t;
      if (Z !== St && Z !== Et && !T()) {
        var c,
            u = p.touches,
            d = S(u ? u[0] : p);
        if (et = C(), u && (J = u.length), b.hold && clearTimeout(rt), Z = Ot, 2 == J && (0 == Q ? (O(1, u[1]), Q = z = M(K[0].start, K[1].start)) : (S(u[1]), z = M(K[0].end, K[1].end), K[0].end, K[1].end, Y = V < 1 ? ht : dt), V = (z / Q * 1).toFixed(2), X = Math.abs(Q - z)), J === b.fingers || b.fingers === $t || !u || x()) {
          if (q = D(d.start, d.end), function(t, e) {
            if (!1 !== b.preventDefaultEvents)
              if (b.allowPageScroll === ft)
                t.preventDefault();
              else {
                var o = b.allowPageScroll === bt;
                switch (e) {
                  case st:
                    (b.swipeLeft && o || !o && b.allowPageScroll != _t) && t.preventDefault();
                    break;
                  case pt:
                    (b.swipeRight && o || !o && b.allowPageScroll != _t) && t.preventDefault();
                    break;
                  case ct:
                    (b.swipeUp && o || !o && b.allowPageScroll != yt) && t.preventDefault();
                    break;
                  case ut:
                    (b.swipeDown && o || !o && b.allowPageScroll != yt) && t.preventDefault()
                }
              }
          }(t, B = D(d.last, d.end)), l = d.start, s = d.end, U = Math.round(Math.sqrt(Math.pow(s.x - l.x, 2) + Math.pow(s.y - l.y, 2))), F = L(), r = U, (a = q) != ft && (r = Math.max(r, E(a)), W[a].distance = r), c = v(p, Z), !b.triggerOnTouchEnd || b.triggerOnTouchLeave) {
            var h = !0;
            if (b.triggerOnTouchLeave) {
              var f = {
                left: (n = (i = lt(i = this)).offset()).left,
                right: n.left + i.outerWidth(),
                top: n.top,
                bottom: n.top + i.outerHeight()
              };
              e = d.end,
                  o = f,
                  h = e.x > o.left && e.x < o.right && e.y > o.top && e.y < o.bottom
            }
            !b.triggerOnTouchEnd && h ? Z = g(Ot) : b.triggerOnTouchLeave && !h && (Z = g(St)),
            Z != Et && Z != St || v(p, Z)
          }
        } else
          v(p, Z = Et);
        !1 === c && v(p, Z = Et)
      }
    }
    function i(t) {
      var e,
          o = t.originalEvent ? t.originalEvent : t,
          i = o.touches;
      if (i) {
        if (i.length && !T())
          return e = o, ot = C(), it = e.touches.length + 1, !0;
        if (i.length && T())
          return !0
      }
      return T() && (J = it), et = C(), F = L(), p() || !s() ? v(o, Z = Et) : b.triggerOnTouchEnd || !1 === b.triggerOnTouchEnd && Z === Ot ? (!1 !== b.preventDefaultEvents && t.preventDefault(), v(o, Z = St)) : !b.triggerOnTouchEnd && w() ? l(o, Z = St, xt) : Z === Ot && v(o, Z = Et), k(!1), null
    }
    function n() {
      z = Q = tt = et = J = 0,
          V = 1,
          $(),
          k(!1)
    }
    function a(t) {
      var e = t.originalEvent ? t.originalEvent : t;
      b.triggerOnTouchLeave && v(e, Z = g(St))
    }
    function r() {
      G.unbind(I, e),
          G.unbind(H, n),
          G.unbind(N, o),
          G.unbind(A, i),
      R && G.unbind(R, a),
          k(!1)
    }
    function g(t) {
      var e = t,
          o = c(),
          i = s(),
          n = p();
      return !o || n ? e = Et : !i || t != Ot || b.triggerOnTouchEnd && !b.triggerOnTouchLeave ? !i && t == St && b.triggerOnTouchLeave && (e = Et) : e = St, e
    }
    function v(t, e) {
      var o,
          i = t.touches;
      return (d() && h() || h()) && (o = l(t, e, gt)), (u() && x() || x()) && !1 !== o && (o = l(t, e, vt)), y() && _() && !1 !== o ? o = l(t, e, mt) : F > b.longTapThreshold && U < Tt && b.longTap && !1 !== o ? o = l(t, e, wt) : 1 !== J && Pt || !(isNaN(U) || U < b.threshold) || !w() || !1 === o || (o = l(t, e, xt)), e === Et && n(), e === St && (i && i.length || n()), o
    }
    function l(t, e, o) {
      var i;
      if (o == gt) {
        if (G.trigger("swipeStatus", [e, q || null, U || 0, F || 0, J, K, B]), b.swipeStatus && !1 === (i = b.swipeStatus.call(G, t, e, q || null, U || 0, F || 0, J, K, B)))
          return !1;
        if (e == St && d()) {
          if (clearTimeout(at), clearTimeout(rt), G.trigger("swipe", [q, U, F, J, K, B]), b.swipe && !1 === (i = b.swipe.call(G, t, q, U, F, J, K, B)))
            return !1;
          switch (q) {
            case st:
              G.trigger("swipeLeft", [q, U, F, J, K, B]),
              b.swipeLeft && (i = b.swipeLeft.call(G, t, q, U, F, J, K, B));
              break;
            case pt:
              G.trigger("swipeRight", [q, U, F, J, K, B]),
              b.swipeRight && (i = b.swipeRight.call(G, t, q, U, F, J, K, B));
              break;
            case ct:
              G.trigger("swipeUp", [q, U, F, J, K, B]),
              b.swipeUp && (i = b.swipeUp.call(G, t, q, U, F, J, K, B));
              break;
            case ut:
              G.trigger("swipeDown", [q, U, F, J, K, B]),
              b.swipeDown && (i = b.swipeDown.call(G, t, q, U, F, J, K, B))
          }
        }
      }
      if (o == vt) {
        if (G.trigger("pinchStatus", [e, Y || null, X || 0, F || 0, J, V, K]), b.pinchStatus && !1 === (i = b.pinchStatus.call(G, t, e, Y || null, X || 0, F || 0, J, V, K)))
          return !1;
        if (e == St && u())
          switch (Y) {
            case dt:
              G.trigger("pinchIn", [Y || null, X || 0, F || 0, J, V, K]),
              b.pinchIn && (i = b.pinchIn.call(G, t, Y || null, X || 0, F || 0, J, V, K));
              break;
            case ht:
              G.trigger("pinchOut", [Y || null, X || 0, F || 0, J, V, K]),
              b.pinchOut && (i = b.pinchOut.call(G, t, Y || null, X || 0, F || 0, J, V, K))
          }
      }
      return o == xt ? e !== Et && e !== St || (clearTimeout(at), clearTimeout(rt), _() && !y() ? (nt = C(), at = setTimeout(lt.proxy(function() {
        nt = null,
            G.trigger("tap", [t.target]),
        b.tap && (i = b.tap.call(G, t, t.target))
      }, this), b.doubleTapThreshold)) : (nt = null, G.trigger("tap", [t.target]), b.tap && (i = b.tap.call(G, t, t.target)))) : o == mt ? e !== Et && e !== St || (clearTimeout(at), clearTimeout(rt), nt = null, G.trigger("doubletap", [t.target]), b.doubleTap && (i = b.doubleTap.call(G, t, t.target))) : o == wt && (e !== Et && e !== St || (clearTimeout(at), nt = null, G.trigger("longtap", [t.target]), b.longTap && (i = b.longTap.call(G, t, t.target)))), i
    }
    function s() {
      var t = !0;
      return null !== b.threshold && (t = U >= b.threshold), t
    }
    function p() {
      var t = !1;
      return null !== b.cancelThreshold && null !== q && (t = E(q) - U >= b.cancelThreshold), t
    }
    function c() {
      return !(b.maxTimeThreshold && F >= b.maxTimeThreshold)
    }
    function u() {
      var t = f(),
          e = m(),
          o = null === b.pinchThreshold || X >= b.pinchThreshold;
      return t && e && o
    }
    function x() {
      return b.pinchStatus || b.pinchIn || b.pinchOut
    }
    function d() {
      var t = c(),
          e = s(),
          o = f(),
          i = m();
      return !p() && i && o && e && t
    }
    function h() {
      return b.swipe || b.swipeStatus || b.swipeLeft || b.swipeRight || b.swipeUp || b.swipeDown
    }
    function f() {
      return J === b.fingers || b.fingers === $t || !Pt
    }
    function m() {
      return 0 !== K[0].end.x
    }
    function w() {
      return b.tap
    }
    function _() {
      return !!b.doubleTap
    }
    function y() {
      if (null == nt)
        return !1;
      var t = C();
      return _() && t - nt <= b.doubleTapThreshold
    }
    function $() {
      it = ot = 0
    }
    function T() {
      var t = !1;
      ot && C() - ot <= b.fingerReleaseThreshold && (t = !0);
      return t
    }
    function k(t) {
      G && (!0 === t ? (G.bind(N, o), G.bind(A, i), R && G.bind(R, a)) : (G.unbind(N, o, !1), G.unbind(A, i, !1), R && G.unbind(R, a, !1)), G.data(Dt + "_intouch", !0 === t))
    }
    function O(t, e) {
      var o = {
        start: {
          x: 0,
          y: 0
        },
        last: {
          x: 0,
          y: 0
        },
        end: {
          x: 0,
          y: 0
        }
      };
      return o.start.x = o.last.x = o.end.x = e.pageX || e.clientX, o.start.y = o.last.y = o.end.y = e.pageY || e.clientY, K[t] = o
    }
    function S(t) {
      var e = void 0 !== t.identifier ? t.identifier : 0,
          o = K[e] || null;
      return null === o && (o = O(e, t)), o.last.x = o.end.x, o.last.y = o.end.y, o.end.x = t.pageX || t.clientX, o.end.y = t.pageY || t.clientY, o
    }
    function E(t) {
      if (W[t])
        return W[t].distance
    }
    function P(t) {
      return {
        direction: t,
        distance: 0
      }
    }
    function L() {
      return et - tt
    }
    function M(t, e) {
      var o = Math.abs(t.x - e.x),
          i = Math.abs(t.y - e.y);
      return Math.round(Math.sqrt(o * o + i * i))
    }
    function D(t, e) {
      if (i = e, (o = t).x == i.x && o.y == i.y)
        return ft;
      var o,
          i,
          n,
          a,
          r,
          l,
          s,
          p,
          c = (a = e, r = (n = t).x - a.x, l = a.y - n.y, s = Math.atan2(l, r), (p = Math.round(180 * s / Math.PI)) < 0 && (p = 360 - Math.abs(p)), p);
      return c <= 45 && 0 <= c || c <= 360 && 315 <= c ? st : 135 <= c && c <= 225 ? pt : 45 < c && c < 135 ? ut : ct
    }
    function C() {
      return (new Date).getTime()
    }
    b = lt.extend({}, b);
    var j = Pt || Mt || !b.fallbackToMouseEvents,
        I = j ? Mt ? Lt ? "MSPointerDown" : "pointerdown" : "touchstart" : "mousedown",
        N = j ? Mt ? Lt ? "MSPointerMove" : "pointermove" : "touchmove" : "mousemove",
        A = j ? Mt ? Lt ? "MSPointerUp" : "pointerup" : "touchend" : "mouseup",
        R = j ? Mt ? "mouseleave" : null : "mouseleave",
        H = Mt ? Lt ? "MSPointerCancel" : "pointercancel" : "touchcancel",
        U = 0,
        q = null,
        B = null,
        F = 0,
        Q = 0,
        z = 0,
        V = 1,
        X = 0,
        Y = 0,
        W = null,
        G = lt(t),
        Z = "start",
        J = 0,
        K = {},
        tt = 0,
        et = 0,
        ot = 0,
        it = 0,
        nt = 0,
        at = null,
        rt = null;
    try {
      G.bind(I, e),
          G.bind(H, n)
    } catch (t) {
      lt.error("events not supported " + I + "," + H + " on jQuery.swipe")
    }
    this.enable = function() {
      return this.disable(), G.bind(I, e), G.bind(H, n), G
    },
        this.disable = function() {
          return r(), G
        },
        this.destroy = function() {
          r(),
              G.data(Dt, null),
              G = null
        },
        this.option = function(t, e) {
          if ("object" == typeof t)
            b = lt.extend(b, t);
          else if (void 0 !== b[t]) {
            if (void 0 === e)
              return b[t];
            b[t] = e
          } else {
            if (!t)
              return b;
            lt.error("Option " + t + " does not exist on jQuery.swipe.options")
          }
          return null
        }
  }
  var st = "left",
      pt = "right",
      ct = "up",
      ut = "down",
      dt = "in",
      ht = "out",
      ft = "none",
      bt = "auto",
      gt = "swipe",
      vt = "pinch",
      xt = "tap",
      mt = "doubletap",
      wt = "longtap",
      _t = "horizontal",
      yt = "vertical",
      $t = "all",
      Tt = 10,
      kt = "start",
      Ot = "move",
      St = "end",
      Et = "cancel",
      Pt = "ontouchstart" in window,
      Lt = window.navigator.msPointerEnabled && !window.PointerEvent && !Pt,
      Mt = (window.PointerEvent || window.navigator.msPointerEnabled) && !Pt,
      Dt = "TouchSwipe";
  lt.fn.swipe = function(t) {
    var e = lt(this),
        o = e.data(Dt);
    if (o && "string" == typeof t) {
      if (o[t])
        return o[t].apply(o, Array.prototype.slice.call(arguments, 1));
      lt.error("Method " + t + " does not exist on jQuery.swipe")
    } else if (o && "object" == typeof t)
      o.option.apply(o, arguments);
    else if (!(o || "object" != typeof t && t))
      return function(o) {
        return !o || void 0 !== o.allowPageScroll || void 0 === o.swipe && void 0 === o.swipeStatus || (o.allowPageScroll = ft), void 0 !== o.click && void 0 === o.tap && (o.tap = o.click), o = o || {}, o = lt.extend({}, lt.fn.swipe.defaults, o), this.each(function() {
          var t = lt(this),
              e = t.data(Dt);
          e || (e = new i(this, o), t.data(Dt, e))
        })
      }.apply(this, arguments);
    return e
  },
      lt.fn.swipe.version = "1.6.18",
      lt.fn.swipe.defaults = {
        fingers: 1,
        threshold: 75,
        cancelThreshold: null,
        pinchThreshold: 20,
        maxTimeThreshold: null,
        fingerReleaseThreshold: 250,
        longTapThreshold: 500,
        doubleTapThreshold: 200,
        swipe: null,
        swipeLeft: null,
        swipeRight: null,
        swipeUp: null,
        swipeDown: null,
        swipeStatus: null,
        pinchIn: null,
        pinchOut: null,
        pinchStatus: null,
        click: null,
        tap: null,
        doubleTap: null,
        longTap: null,
        hold: null,
        triggerOnTouchEnd: !0,
        triggerOnTouchLeave: !1,
        allowPageScroll: "auto",
        fallbackToMouseEvents: !0,
        excludedElements: ".noSwipe",
        preventDefaultEvents: !0
      },
      lt.fn.swipe.phases = {
        PHASE_START: kt,
        PHASE_MOVE: Ot,
        PHASE_END: St,
        PHASE_CANCEL: Et
      },
      lt.fn.swipe.directions = {
        LEFT: st,
        RIGHT: pt,
        UP: ct,
        DOWN: ut,
        IN: dt,
        OUT: ht
      },
      lt.fn.swipe.pageScroll = {
        NONE: ft,
        HORIZONTAL: _t,
        VERTICAL: yt,
        AUTO: bt
      },
      lt.fn.swipe.fingers = {
        ONE: 1,
        TWO: 2,
        THREE: 3,
        FOUR: 4,
        FIVE: 5,
        ALL: $t
      }
}),
    $(document).ready(function() {
      var o = "topbox",
          i = {
            effect: "fade",
            backgroundBlur: !1,
            skin: "darkroom",
            keyboardNav: !0,
            clickImgToClose: !1,
            clickOverlayToClose: !0,
            closeToolTip: "Закрыть",
            previousToolTip: "Previous",
            nextToolTip: "Next",
            titleSource: "title",
            onInit: function() {},
            beforeShowLightbox: function() {},
            afterShowLightbox: function(t) {},
            beforeHideLightbox: function() {},
            afterHideLightbox: function() {},
            beforePrev: function(t) {},
            onPrev: function(t) {},
            beforeNext: function(t) {},
            onNext: function(t) {},
            pdfMessage: "View the PDF in a new window:",
            pdfButton: "Tap Here",
            errorMessage: "The requested content cannot be loaded. Please try again later."
          };
      function e(t, e) {
        this.el = t,
            this.$el = $(this.el),
            this.options = $.extend({}, i, e),
            this._defaults = i,
            this._name = o,
            this.init()
      }
      e.prototype = {
        init: function() {
          var o = this;
          /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || $("body").addClass("topbox_notouch"),
          this.options.backgroundBlur && $("body").wrapInner('<div class="topbox_blur_wrapper" />'),
              this.$el.on("click", function(t) {
                o.showLightbox(t)
              }),
          this.options.keyboardNav && $("body").off("keyup").on("keyup", function(t) {
            var e = t.keyCode ? t.keyCode : t.which;
            27 == e && o.destructLightbox(t),
            37 == e && $(".topbox_prev").trigger("click"),
            39 == e && $(".topbox_next").trigger("click")
          }),
              this.options.onInit.call(this)
        },
        showLightbox: function(t) {
          var o = this,
              i = this.$el;
          if (this.checkContent(i)) {
            t.preventDefault(),
                this.options.beforeShowLightbox.call(this);
            var e = this.constructLightbox();
            if (e) {
              var n = e.find(".topbox_content");
              if (n) {
                if ($("body").addClass("topbox_open topbox_body_effect_" + this.options.effect), this.processContent(n, i), this.$el.attr("data-lightbox-gallery")) {
                  var a = $('[data-lightbox-gallery="' + this.$el.attr("data-lightbox-gallery") + '"]');
                  $(".topbox_nav").show(),
                      $(".topbox_prev").off("click").on("click", function(t) {
                        t.preventDefault(),
                            $(".topbox_content").empty();
                        var e = a.index(i);
                        i = a.eq(e - 1),
                        $(i).length || (i = a.last()),
                            $.when(o.options.beforePrev.call(this, [i])).done(function() {
                              o.processContent(n, i),
                                  o.options.onPrev.call(this, [i])
                            })
                      }),
                      $(".topbox_next").off("click").on("click", function(t) {
                        t.preventDefault(),
                            $(".topbox_content").empty();
                        var e = a.index(i);
                        i = a.eq(e + 1),
                        $(i).length || (i = a.first()),
                            $.when(o.options.beforeNext.call(this, [i])).done(function() {
                              o.processContent(n, i),
                                  o.options.onNext.call(this, [i])
                            })
                      })
                }
                setTimeout(function() {
                  e.addClass("topbox_open"),
                      o.options.afterShowLightbox.call(this, [e])
                }, 1)
              }
            }
          }
        },
        checkContent: function(t) {
          var e = t.attr("href"),
              o = e.match(/(youtube|youtube-nocookie|youtu|vimeo)\.(com|be|ly|tv)\/((watch\?v=([\w-]+))|([\w-]+))/);
          return videoOther = e.match(/(dai|brighteon|ted)\.(com|be|ly|tv|net)\/((talks\/([\w-]+))|([\w-]+))/), null !== e.match(/\.(jpeg|jpg|gif|png|tiff|svg|webp)$/i) || (null !== e.match(/\.(ogg|mp3)$/i) || (null !== e.match(/\.(ogv|mov|webm|mp4)$/i) || (null !== e.match(/\.(pdf)$/i) || (!!o || (!!videoOther || ("ajax" == t.attr("data-lightbox-type") || ("#" == e.substring(0, 1) && "inline" == t.attr("data-lightbox-type") || ("video-iframe" == t.attr("data-lightbox-type") || "iframe" == t.attr("data-lightbox-type")))))))))
        },
        processContent: function(e, o) {
          var i = this,
              t = o.attr("href"),
              n = t.match(/(youtube|youtube-nocookie|youtu|vimeo)\.(com|be|ly|tv)\/((watch\?v=([\w-]+))|([\w-]+))/);
          if (videoOther = t.match(/(dai|brighteon|ted)\.(com|be|net|ly|tv)\/((talks\/([\w-]+))|([\w-]+))/), null !== t.match(/\.(jpeg|jpg|gif|png|tiff|svg|webp)$/i)) {
            var a = $("<img>", {
              src: t,
              class: "topbox_image"
            });
            a.one("load", function() {
              e.prepend(a)
            }).each(function() {
              this.complete && $(this).on("load")
            }),
                a.on("error", function() {
                  var t = $('<div class="topbox_error"><p>' + i.options.errorMessage + "</p></div>");
                  e.html(t)
                })
          } else if (n) {
            var r = "",
                l = "topbox_embedded_web_video";
            if ("youtube" == n[1] && (r = "https://www.youtube.com/embed/" + n[4] + "?autoplay=1&amp;rel=0", l = "topbox_youtube"), "youtube-nocookie" == n[1] && (r = t, l = "topbox_youtube"), "youtu" == n[1] && (r = "https://www.youtube.com/embed/" + n[3] + "?autoplay=1&amp;rel=0", l = "topbox_youtube"), "vimeo" == n[1] && (r = "https://player.vimeo.com/video/" + n[3] + "?autoplay=1&amp;rel=0&amp;playsinline=0", l = "topbox_vimeo"), r) {
              var s = $("<iframe>", {
                src: r,
                class: l,
                frameborder: 0,
                vspace: 0,
                hspace: 0,
                scrolling: "auto",
                allowfullscreen: "true",
                allow: "autoplay; encrypted-media"
              });
              e.prepend(s),
                  $("iframe").wrap('<div class="topbox_embedded_web_video" id="player" />')
            }
          } else if (videoOther) {
            r = "",
                l = "topbox_embedded_web_video";
            if ("dai" == videoOther[1] && (r = "https://www.dailymotion.com/embed/video/" + videoOther[3] + "?autoPlay=1", l = "topbox_dailymotion"), "ted" == videoOther[1] && (r = "https://embed.ted.com/" + videoOther[3] + "?autoplay=1", l = "topbox_ted"), "brighteon" == videoOther[1] && (r = "https://www.brighteon.com/embed/" + videoOther[3] + "?autoplay=1", l = "topbox_brighteon"), r) {
              s = $("<iframe>", {
                src: r,
                class: l,
                frameborder: 0,
                vspace: 0,
                hspace: 0,
                scrolling: "auto",
                allowfullscreen: "true",
                allowtransparency: "true",
                allow: "autoplay; encrypted-media"
              });
              e.prepend(s),
                  $("iframe").wrap('<div class="topbox_embedded_web_video" />')
            }
          } else if ("ajax" == o.attr("data-lightbox-type"))
            $.ajax({
              url: t,
              cache: !1,
              success: function(t) {
                if ($(".topbox_content").is(":empty")) {
                  if (o.data("lightbox-size")) {
                    var e = o.data("lightbox-size");
                    $(".topbox_content").addClass(e)
                  }
                  $(".topbox_content").append(t)
                } else
                  $(".topbox_content > div").hide(),
                      $(".topbox_content").append(t),
                  o.data("delete") && (console.log("delete"), setTimeout(function() {
                    $(".topbox_content > div").eq(-2).remove()
                  }, 100));
                $(".js_lightbox").topbox()
              },
              error: function() {
                var t = $('<div class="topbox_error"><p>' + i.options.errorMessage + "</p></div>");
                e.html(t)
              }
            });
          else if ("#" == t.substring(0, 1) && "inline" == o.attr("data-lightbox-type"))
            if ($(t).length) {
              var p = $('<div class="topbox_inline" />');
              p.append($(t).clone().show()),
                  e.html(p)
            } else {
              var c = $('<div class="topbox_error"><p>' + i.options.errorMessage + "</p></div>");
              e.html(c)
            }
          else if (null !== t.match(/\.(pdf)$/i))
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
              var u = t,
                  d = $('<div class="topbox_pdf_mobile_message"><p>' + i.options.pdfMessage + '<br><a href="' + u + '" target="_blank" class="topbox_pdf_mobile_button">' + i.options.pdfButton + "</a></p></div>");
              e.html(d)
            } else {
              var h = $("<iframe>", {
                src: t,
                class: "topbox_pdf",
                frameborder: 0,
                vspace: 0,
                hspace: 0,
                scrolling: "auto"
              });
              e.html(h),
                  $("iframe.topbox_pdf").wrap('<div class="topbox_pdf_wrap" />')
            }
          else if (null !== t.match(/\.(ogg|mp3)$/i)) {
            var f = $("<audio>", {
              src: t,
              id: "player",
              class: "topbox_html5audio",
              controls: "true",
              playsinline: "true",
              preload: "auto",
              autoplay: "true"
            });
            e.html(f),
                $("audio.topbox_html5audio").wrap('<div class="topbox_html5_audio_wrap" />')
          } else if (null !== t.match(/\.(ogv|mov|webm|mp4)$/i)) {
            var b = $("<video>", {
              src: t,
              id: "player",
              class: "topbox_html5video",
              controls: "true",
              playsinline: "true",
              preload: "auto",
              autoplay: "true"
            });
            e.html(b),
                $("video.topbox_html5video").wrap('<div class="topbox_html5_video_wrap" />')
          } else if ("iframe" == o.attr("data-lightbox-type")) {
            h = $("<iframe>", {
              src: t,
              class: "topbox_iframe",
              frameborder: 0,
              vspace: 0,
              hspace: 0,
              scrolling: "auto"
            });
            e.html(h),
                $("iframe.topbox_iframe").wrap('<div class="topbox_iframe_wrap" />')
          } else {
            if ("video-iframe" != o.attr("data-lightbox-type"))
              return !1;
            h = $("<iframe>", {
              src: t,
              class: "topbox_video_iframe",
              frameborder: 0,
              vspace: 0,
              hspace: 0,
              scrolling: "auto"
            });
            e.html(h),
                $("iframe.topbox_video_iframe").wrap('<div class="topbox_video_iframe_wrap" />')
          }
          if (o.attr(this.options.titleSource)) {
            var g = $("<span>", {
              id: "topbox_title",
              class: "topbox_title"
            });
            g.text(o.attr(this.options.titleSource)),
                $(".topbox_title_wrap").html(g)
          } else
            $(".topbox_title_wrap").html("")
        },
        constructLightbox: function() {
          if ($(".topbox_overlay").length)
            return $(".topbox_overlay");
          var t = $("<div>", {
                "aria-labelledby": "topbox_title",
                role: "dialog",
                class: "topbox_overlay topbox_skin_" + this.options.skin + " topbox_effect_" + this.options.effect
              }),
              e = $('<div class="topbox_fill"></div>'),
              o = $("<div>", {
                class: "topbox_wrapper"
              }),
              i = $("<div>", {
                class: "topbox_content"
              }),
              n = $("<div>", {
                class: "topbox_title_wrap"
              }),
              a = $('<a href="#" class="topbox_nav topbox_prev" tabindex="2" title="' + this.options.previousToolTip + '"></a><a href="#" class="topbox_nav topbox_next" tabindex="3" title="' + this.options.nextToolTip + '"></a>'),
              r = $('<a href="#" class="topbox_close" tabindex="1" title="' + this.options.closeToolTip + '"></a>');
          t.append(e),
              o.append(i),
              o.append(n),
              t.append(o),
              t.append(a),
              t.append(r),
              $("body").append(t);
          var l = this;
          return l.options.clickOverlayToClose && t.on("click", function(t) {
            (t.target === this || $(t.target).hasClass("topbox_wrapper") || $(t.target).hasClass("topbox_content")) && l.destructLightbox(t)
          }), l.options.clickImgToClose && t.on("click", function(t) {
            t.target !== this && !$(t.target).hasClass("topbox_image") || l.destructLightbox(t)
          }), r.on("click", function(t) {
            t.preventDefault(),
                l.destructLightbox(t)
          }), $("body").on("click", ".js_close_nivo", function(t) {
            t.preventDefault(),
                l.destructLightbox(t),
                t.stopImmediatePropagation()
          }), $(".topbox_closer").on("click", function(t) {
            t.preventDefault(),
                l.destructLightbox(t),
                t.stopImmediatePropagation()
          }), $("*[tabindex]").each(function(t) {
            $(this).data("tabindex", $(this).attr("tabindex")).attr("tabindex", "-1")
          }), $(".topbox_overlay *[tabindex]").each(function(t) {
            $(this).attr("tabindex", $(this).data("tabindex"))
          }), t
        },
        destructLightbox: function(t) {
          if (1, 1 < $(".topbox_content > div").length)
            console.log("hide", $(".topbox_content > div").length),
                $(".topbox_content > div:last-child").remove(),
                $(".topbox_content > div:last-child").show();
          else {
            console.log("close", $(".topbox_content > div").length);
            this.options.beforeHideLightbox.call(this),
                $(".topbox_overlay").removeClass("topbox_open"),
                $(".topbox_nav").hide(),
                $("body").removeClass("topbox_open topbox_body_effect_" + this.options.effect),
                $(".topbox_prev").off("click"),
                $(".topbox_next").off("click"),
                $(".topbox_content").empty(),
                $(".topbox_content").removeClass().addClass("topbox_content"),
                $("*[tabindex]").each(function(t) {
                  $(this).attr("tabindex", $(this).data("tabindex"))
                }),
                this.options.afterHideLightbox.call(this)
          }
          isRezeble = !0,
              t.stopImmediatePropagation()
        }
      },
          $.fn[o] = function(t) {
            return this.each(function() {
              $.data(this, o) || $.data(this, o, new e(this, t))
            })
          };
      $(document).on("click", ".lightbox", function() {
        var t;
        t = ".topbox_overlay",
            $(t).swipe("destroy"),
            $(t).swipe({
              swipe: function(t, e, o, i, n, a) {
                "left" == e && $(".topbox_next").trigger("click"),
                "right" == e && $(".topbox_prev").trigger("click")
              }
            })
      })
    });

